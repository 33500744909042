






























import Vue from "vue";
import { EmailVerified } from "@/interfaces/auth/email_verified";
import router from "@/router";
import { UserState } from "@/store/modules/auth/interfaces";
import { GET_USER_DETAILS, USER_STATE } from "@/store/modules/auth/constants";
import { mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  name: "EmailVerificationSuccess",
  data(): EmailVerified {
    return {
      decoration_img_src: require("@/assets/illustrations/mail.svg")
    };
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    async proceed_to_onboarding() {
      this.update_user_state(UserState.PENDING_VERIFICATION);
      await router.push("/auth/pending-verification");
    },
    async navigate_to_login() {
      this.update_user_state(UserState.UNAUTHENTICATED);
      await router.push("/auth/login");
    }
  }
});
